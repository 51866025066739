<script>
import FilterComponent from './components/InvoicesFilterComponent.vue'

export default {
  extends: FilterComponent,
  name: 'InvoicesFiltersComponent',
  data() {
    return {
      path: 'invoices',
    }
  },
}
</script>
