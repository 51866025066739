<template>
  <v-card flat>
    <v-expansion-panels class="mb-8" v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtros</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="filterForm" @submit.prevent="submitFilter()">
            <v-row dense>
              <v-col cols="12" md="2">
                <input-date
                  v-model="filters.creationStartTime"
                  :label="$t('creationStartTime')"
                  :rules="[requiredRule, dateLength]"
                  clearable
                  dense
                />
              </v-col>
              <v-col cols="12" md="2">
                <input-date
                  v-model="filters.creationFinishTime"
                  :label="$t('creationFinishTime')"
                  :rules="[
                    requiredRule,
                    dateLength,
                    dataIntervalMustBeLessThanDays(
                      filters.creationStartTime,
                      filters.creationFinishTime,
                      searchDateLimitDays
                    ),
                  ]"
                  clearable
                  dense
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filters.companyCode"
                  :items="companyCodeList"
                  :label="filtroEmpresa"
                  :rules="[requiredRule]"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filters.emmiterCode"
                  :rules="[length(filters.emmiterCode, 14)]"
                  v-mask="'##############'"
                  :label="$t('emmiterCode')"
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  v-model="filters.cteNumber"
                  :label="$t('cteNumber')"
                  :items="filters.cteNumber"
                  @paste.prevent="pasteData($event, 'cteNumber')"
                  @keydown.space="pushManualValue($event, 'cteNumber')"
                  class="multipleDocs"
                  @click:clear="filters.cteNumber = []"
                  outlined
                  clearable
                  multiple
                  chips
                  deletable-chips
                  small-chips
                  :allow-overflow="false"
                  :no-data-text="'Informe os CTe\'s'"
                  dense
                  :append-icon="''"
                  menu-props="closeOnContentClick"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  v-model="filters.nfNumber"
                  :label="$t('nfNumber')"
                  :items="filters.nfNumber"
                  @paste.prevent="pasteData($event, 'nfNumber')"
                  @keydown.space="pushManualValue($event, 'nfNumber')"
                  class="multipleDocs"
                  @click:clear="filters.nfNumber = []"
                  outlined
                  clearable
                  multiple
                  chips
                  deletable-chips
                  small-chips
                  :allow-overflow="false"
                  :no-data-text="'Informe as notas fiscais'"
                  dense
                  :append-icon="''"
                  menu-props="closeOnContentClick"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="5">
                    <v-btn depressed color="#D8D8D8" :block="$vuetify.breakpoint.xsOnly" type="submit">
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <TLoading v-model="loading" />
  </v-card>
</template>

<script>
import mixinFilters from '@/mixins/mixinFilter'
import mixinRules from '@/mixins/mixinRules'
import InputDate from '@/components/InputDate.vue'

export default {
  name: 'InvoicesFilterComponent',
  mixins: [mixinFilters, mixinRules],
  components: {
    InputDate,
  },
  data() {
    return {
      path: '',
      panels: [0],
      searchDateLimitDays: 30,
      filters: {
        startTime: '',
        finishTime: '',
        companyCode: '',

        creationStartTime: '',
        creationFinishTime: '',
        cteNumber: [],
        nfNumber: [],
        emmiterCode: '',
      },
    }
  },
  computed: {
    cptdRuleNfeCte() {
      return this.filters.nfNumber &&
        this.filters.nfNumber.length > 0 &&
        this.filters.cteNumber &&
        this.filters.cteNumber.length > 0
        ? 'Não é possível filtrar por este campo. Motivo: filtro de CTE preenchido.'
        : true
    },
  },
  methods: {
    prepareFilter() {
      const filter = {}
      this.verifyNullableFilter(filter, 'startTime', this.filters.startTime, 'T00:00:00-03:00')
      this.verifyNullableFilter(filter, 'finishTime', this.filters.finishTime, 'T23:59:59-03:00')
      this.verifyNullableFilter(filter, 'companyCode', this.filters.companyCode)

      this.verifyNullableFilter(filter, 'creationStartTime', this.filters.creationStartTime, `T00:00:00-03:00`)
      this.verifyNullableFilter(filter, 'creationFinishTime', this.filters.creationFinishTime, `T23:59:59-03:00`)
      this.verifyNullableFilter(filter, 'cteNumber', this.filters.cteNumber)
      this.verifyNullableFilter(filter, 'nfNumber', this.filters.nfNumber)
      this.verifyNullableFilter(filter, 'emmiterCode', this.filters.emmiterCode)

      return filter
    },
    findKey(object, key, value) {
      Object.keys(object).some(function (k) {
        if (k === key) {
          if (typeof object[k] == 'boolean') {
            object[k] = JSON.parse(value)
          } else {
            object[k] = value
          }
          return true
        }
      })
    },
  },
}
</script>

<style scoped>
.v-autocomplete__content {
  display: none !important;
}

.v-expansion-panels {
  z-index: 0 !important;
}
</style>
